import React from 'react';
import cl from "./Sidebar.module.css";
import logo from "../../../../assets/images/logo.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUser,
    faRightFromBracket,
    faHome,
    faNewspaper,
    faPeopleRoof,
    faChartColumn
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../../../features/authentication/authenticationSlice";
import SidebarItem from "./SidebarItem/SidebarItem";
import ToggleSidebarItem from "./ToggleSidebarItem/ToggleSidebarItem";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import PermissionType from "../../../../data/enums/PermissionType";
import {useNavigate} from "react-router-dom";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";

const Sidebar = () => {
    const user = useSelector(selectCurrentUser);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOutUser = () => {
        localStorage.removeItem('_d');
        dispatch(logOut());
    }

    const navigateToProfile = () => {
        navigate('/profile/me');
    }

    if (!user) {
        return <LoadingScreen/>
    }

    if (user && !user.permission) {
        throw new Error('User has no permissions');
    }

    return (
        <div className={cl.sidebar}>
            <div>
                <img className={cl.logo} src={logo} alt='logo'/>
            </div>
            <div>
                <SidebarItem path='/' text={t("sidebar.menu.main-page")} icon={<FontAwesomeIcon icon={faHome}/>}>

                </SidebarItem>
                <ToggleSidebarItem path='/news' text={t("sidebar.menu.news")}
                                   icon={<FontAwesomeIcon icon={faNewspaper}/>}>
                    {user.permission.news !== PermissionType.NotAvailable ?
                        <SidebarItem path='/news/posts' text={t("sidebar.menu.posts")}>

                        </SidebarItem>
                        : null
                    }
                    {user.permission.views !== PermissionType.NotAvailable ?
                        <SidebarItem path='/news/pages' text={t("sidebar.menu.pages")}>

                        </SidebarItem>
                        : null
                    }
                    {user.permission.newsSources !== PermissionType.NotAvailable ?
                        <SidebarItem path='/news/sources' text={t("sidebar.menu.post-sources")}>

                        </SidebarItem>
                        : null
                    }
                    {user.permission.newsSources !== PermissionType.NotAvailable ?
                        <SidebarItem path='/news/imagetemplates' text={t("sidebar.menu.post-image-templates")}>

                        </SidebarItem>
                        : null
                    }
                    <SidebarItem path='/news/categories' text={t("sidebar.menu.post-categories")}>

                    </SidebarItem>
                    {user.permission.adds !== PermissionType.NotAvailable ?
                        <SidebarItem path='/news/banners' text={t("sidebar.menu.banners")}>

                        </SidebarItem>
                        : null
                    }
                    {user.permission.newsSources !== PermissionType.NotAvailable ?
                        <SidebarItem end path='/management/links' text={t("sidebar.menu.links")}>

                        </SidebarItem>
                        : null
                    }

                </ToggleSidebarItem>
                <ToggleSidebarItem path='/users' text={t("sidebar.menu.users")} icon={<FontAwesomeIcon icon={faUser}/>}>
                    <SidebarItem end path='/users' text={t("sidebar.menu.users")}>

                    </SidebarItem>
                    {user.permission.userLog !== PermissionType.NotAvailable ?
                        <SidebarItem end path='/users/logs' text={t("sidebar.menu.user-logs")}>

                        </SidebarItem>
                        : null
                    }
                    {user.permission.userLog !== PermissionType.NotAvailable ?
                        <SidebarItem end path='/users/accounts' text={t("sidebar.menu.accounts")}>

                        </SidebarItem>
                        : null
                    }
                    {user.permission.userLog !== PermissionType.NotAvailable ?
                        <SidebarItem end path='/users/polls' text={t("sidebar.menu.polls")}>

                        </SidebarItem>
                        : null
                    }
                </ToggleSidebarItem>
                {user.permission.admins !== PermissionType.NotAvailable || user.permission.settings !== PermissionType.NotAvailable
                    ? <ToggleSidebarItem path='/management' text={t("sidebar.menu.management")}
                                         icon={<FontAwesomeIcon icon={faPeopleRoof}/>}>
                        {user.permission.admins !== PermissionType.NotAvailable ?
                            <SidebarItem end path='/management/admins' text={t("sidebar.menu.admins")}>

                            </SidebarItem>
                            : null
                        }
                        {user.permission.settings !== PermissionType.NotAvailable ?
                            <SidebarItem end path='/management/settings' text={t("sidebar.menu.settings")}>

                            </SidebarItem>
                            : null
                        }

                        {user.permission.settings !== PermissionType.NotAvailable ?
                            <SidebarItem end path='/management/homeownerpolls' text={t("sidebar.menu.homeownerpolls")}>

                            </SidebarItem>
                            : null
                        }
                        {user.permission.settings !== PermissionType.NotAvailable ?
                            <SidebarItem end path='/management/homeownererips' text={t("sidebar.menu.homeownererips")}>

                            </SidebarItem>
                            : null
                        }
                        {user.permission.newsSources !== PermissionType.NotAvailable ?
                            <SidebarItem end path='/management/videocameras' text={t("sidebar.menu.videocameras")}>

                            </SidebarItem>
                            : null
                        }
                    </ToggleSidebarItem>
                    : null
                }
                <ToggleSidebarItem path='/analytics' text={t("sidebar.menu.analytics")}
                                   icon={<FontAwesomeIcon icon={faChartColumn}/>}>
                    <SidebarItem end path='/analytics/users' text={t("sidebar.menu.users")}>

                    </SidebarItem>
                    <SidebarItem end path='/analytics/users/active' text={t("sidebar.menu.active-users")}>

                    </SidebarItem>
                    <SidebarItem end path='/analytics/users/problem' text={t("sidebar.menu.problem-users")}>

                    </SidebarItem>
                </ToggleSidebarItem>
            </div>
            <div>
                <div className={cl.userLogo}
                     title={t("sidebar.profile-label")}
                     onClick={navigateToProfile}>
                    <FontAwesomeIcon icon={faUser}/>
                </div>
                <div className={cl.userName}
                     title={t("sidebar.profile-label")}
                     onClick={navigateToProfile}>
                    {user && user.name}
                </div>
                <div className={cl.logOut}
                     title={t("sidebar.logOut-label")}
                     onClick={logOutUser}>
                    <FontAwesomeIcon icon={faRightFromBracket}/>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
